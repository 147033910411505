import React from "react";
import styled, { css } from "styled-components";
import Image from "../../ImageQuerys/LogoImages";

const Partners = (props) => {
  const { className, logos } = props;
  return (
    <section className={className}>
      {logos.map((l) => (
        <Image className="partner" imageName={l} />
      ))}
    </section>
  );
};

export default styled(Partners)`
  ${({ paddingAll }) =>
    paddingAll
      ? css`
          padding: 7rem 10% 14rem 10%;
        `
      : css`
          padding: 7rem 10% 0 10%;
        `};
  display: flex;
  gap: 5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1070px) {
    justify-content: center;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    gap: 8rem;
  }
  img {
    object-fit: contain !important;
  }
  .partner {
    max-width: 20rem;
    width: 100%;
  }
`;

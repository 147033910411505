import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = (props) => {
  const { className, imageName } = props;

  const data = useStaticQuery(graphql`
    query LogoImages {
      allFile(
        filter: {
          relativePath: {}
          relativeDirectory: { eq: "logos" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return data.allFile.edges.map(({ node }) => {
    if (node.base === imageName) {
      const image = getImage(node);
      return <GatsbyImage className={className} image={image} alt="image" />;
    }
  });
};

export default styled(Image)`
`;

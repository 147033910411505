import React from "react";
import styled from "styled-components";
import { palette } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const First = (props) => {
  const { className } = props;
  const { t } = useTranslation("digitalCampus");

  return (
    <section className={className}>
      <h2>{t("firstTitle")}</h2>
      <p className="desc">{t("firstDesc")}</p>
      <hr />
    </section>
  );
};

export default styled(First)`
  padding: 9rem 16% 0 16%;
  height: fit-content;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  row-gap: 2rem;
  @media(max-width: 600px){
    padding: 10% 8% 0 10%;
    hr{
      width: 80% !important;
    }
  }
  h2 {
    font-size: 4.8rem;
    text-align: center;
    max-width: 1090px;
    width: 100%;
    font-weight: 400;
  }
  .desc {
    font-family: SofiaProLightAz, sans-serif;
    font-size: 2rem;
    line-height: 3.2rem;
    text-align: center;
    max-width: 798px;
    width: 100%;
    margin-bottom: 10rem;
    color: ${palette.grey};
  }
  .firsImg {
    width: 90%;
  }
  hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${palette.greyChevron};
  }
`;

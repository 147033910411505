import React from "react";
import styled from "styled-components";
import { getFlex, palette } from "../../styles/styles";
import left from "../../images/icons/quoteLeft.png";
import right from "../../images/icons/quoteRight.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import loadable from "@loadable/component";
const Carousel = loadable(() => import("react-material-ui-carousel"));

const Quotes = (props) => {
  const { className, nameSpace, data } = props;
  const { t } = useTranslation(nameSpace);

  return (
    <section className={className}>
      <Carousel
        className="quotesCarousel"
        autoPlay
        indicatorIconButtonProps={{
          style: {
            color: palette.blue,
            border: "solid 1px white",
            marginRight: "1rem",
            fontSize: "12px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "white",
            color: "white",
          },
        }}
        indicatorContainerProps={{
          style: {
            textAlign: "center",
            position: "absolute",
            bottom: "8%",
          },
        }}
      >
        {data.map((item) => (
          <>
            <div className="quote">
              <div className="iconContainer">
                <img className="icon" src={left} alt="" />
              </div>
              <div className="text">
                <h3>{t(item.quote)}</h3>
                <p>{t(item.author)}</p>
              </div>
              <div className="iconContainer">
                <img className="icon" src={right} alt="" />
              </div>
            </div>
          </>
        ))}
      </Carousel>
    </section>
  );
};

export default styled(Quotes)`
  padding: 0 8% 5rem 8%;
  height: 60rem;

  background-color: ${palette.blueTwo};
  @media (max-width: 1024px) {
    padding: 0 6%;
    height: 62rem;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    height: 85rem;
    padding: 6% 0 0 0;
  }
  .quotesCarousel {
    padding-top: 8%;
    height: 100%;
    max-height: fit-content;
    margin: auto;
    width: 90%;
    @media (max-width: 600px) {
      width: 90%;
    }
    .quote {
      ${getFlex("row", "ct", "flex-start")};
      height: 100%;
      .iconContainer {
        width: 10%;
        ${getFlex("row", "ct", "st")};
        .icon {
          margin-top: -2rem;
          width: 4rem;
        }
      }
      .text {
        width: 80%;
        h3 {
          color: ${palette.whiteWhite};
          font-size: 3.8rem;
          text-align: center;
          margin-bottom: 2rem;
          font-weight: 400;
          @media (max-width: 600px) {
            font-size: 3.4rem;
          }
          @media (max-width: 500px) {
            font-size: 3.2rem;
          }
        }
        p {
          color: ${palette.orange};
          font-size: 2rem;
          margin: auto;
          text-align: center;
          font-family: SofiaProSemiBoldAz, sans-serif;
        }
      }
    }
  }
  .MuiSvgIcon-root {
    font-size: 12px !important;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import Image from "../../ImageQuerys/SlideImages";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  ChevronRight,
  ChevronLeft,
} from "styled-icons/fluentui-system-filled";
import { Link } from "gatsby";

const Slides = (props) => {
  const { className, data, fromHiring, fromDigital } = props;
  const { t } = useTranslation("slides");
  const [currentSlide, setCurrentSlide] = useState(0);
  const changeSlide = (dir) => {
    if (dir) {
      if (currentSlide === data.length - 1) {
        setCurrentSlide(0);
        return;
      }
      setCurrentSlide((prev) => prev + 1);
    } else {
      if (currentSlide === 0) {
        setCurrentSlide(data.length - 1);
        return;
      }
      setCurrentSlide((prev) => prev - 1);
    }
  };

  return (
    <section className={className}>
      <div
        className="left"
        style={{
          backgroundColor: fromDigital
            ? data[currentSlide].bg
            : palette.greenLight,
          justifyContent:
            fromDigital && currentSlide === 2 ? "flex-end" : "center",
          alignItems: fromDigital && currentSlide === 2 ? "flex-end" : "center",
        }}
      >
        <Image
          className={fromDigital && currentSlide === 2 ? "img100" : "img"}
          imageName={data[currentSlide].image}
          alt=""
        />
      </div>
      <div className="right">
        <div className="titles">
          <div className="redAndControls">
            <div className="newFeature">
              <p className="red">{t(data[currentSlide].red)}</p>
              {fromHiring ? <em>{t("newFeature")}</em> : null}
            </div>
            {data.length > 1 ? (
              <div className="controls">
                <button onClick={() => changeSlide(0)}>
                  <ChevronLeft />
                </button>
                <button onClick={() => changeSlide(1)}>
                  <ChevronRight />
                </button>
              </div>
            ) : null}
          </div>
          <h2>{t(data[currentSlide].title)}</h2>
        </div>
        <p className="desc">{t(data[currentSlide].desc)}</p>
        <Link className="rouge" to={data[currentSlide].to} {...(data[currentSlide].blank ? {target: "_blank"} : {})}>
          {t(data[currentSlide].button)}
        </Link>
        <Image
          className={"mobileImg"}
          imageName={data[currentSlide].image}
          alt=""
        />
      </div>
    </section>
  );
};

export default styled(Slides)`
  display: grid;
  height: 60rem;

  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: auto;
    height: fit-content;
  }

  .mobileImg {
    display: none;
  }
  .left {
    ${getFlex("row", "ct", "ct")};
    @media (max-width: 600px) {
      display: none;
    }
    .img {
      width: 80%;
      max-width: 62rem !important;
    }
    .img100 {
      width: 100%;
    }
  }
  .right {
    display: grid;
    grid-template-rows: repeat(3, max-content);
    justify-content: flex-start;
    align-content: space-evenly;
    background-color: ${palette.bgGrey};
    padding: 8% 10%;

    .redLabel {
      font-family: SofiaProSemiBoldAz, sans-serif;
      color: ${palette.orange};
      font-size: 2rem;
      width: fit-content;
    }

    h2 {
      text-align: start;
      width: 100%;
      font-weight: 400;
      @media (max-width: 600px) {
        font-size: 3.8rem;
      }
    }

    .redAndControls {
      ${getFlex("row", "sb", "ct")};
      .newFeature {
        ${getFlex("row", "st", "ct")}
        .red {
          font-size: 2.4rem;
          line-height: 3.2rem;
          font-family: SofiaProSemiBoldAz, sans-serif;
          width: fit-content;
          color: ${palette.orange};
          @media (max-width: 600px) {
            font-size: 2rem;
          }
        }
        em {
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 3.2rem;
          font-family: SofiaProLightAz, sans-serif;
          width: fit-content;
          color: ${palette.orange};
          margin: -2rem 0 0 1rem;
        }
      }
      .controls {
        ${getFlex("row", "sb", "ct")};
        column-gap: 2rem;
        @media (max-width: 600px) {
          margin-top: -2rem;
        }
        button {
          border: none;
          outline: none;
          background: ${palette.blueChevron} 0% 0% no-repeat padding-box;
          color: ${palette.whiteWhite};
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          @media (max-width: 600px) {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }

    .desc {
      font-size: 1.6rem;
      line-height: 3.2rem;
      font-family: SofiaProLightAz, sans-serif;
      width: 100%;
      color: ${palette.grey};
      margin-bottom: 2.5rem;
    }

    .rouge {
      ${linkRouge};
    }

    // mobile styles
    @media (max-width: 600px) {
      padding: 10% 8%;
      grid-template-rows: repeat(4, max-content);
      grid-row-gap: 3rem;
      justify-items: center;
      text-align: center;
      .titles {
        ${getFlex("column", "ct", "ct")};
        .redAndControls {
          display: flex;
          flex-direction: column-reverse;
          gap: 3.2rem;
          width: 100%;
          .newFeature {
            justify-self: center;
            grid-column: 2/3;
            em {
              display: none;
            }
          }
        }
        .controls {
          grid-column: 3/-1;
          justify-content: flex-end;
        }
        h2 {
          width: fit-content;
          text-align: center;
        }
      }
      .mobileImg {
        display: flex !important;
        width: 80%;
      }
    }
  }
`;
